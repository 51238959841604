import React from "react";
import Navigation from "./Navigation";

const Header = () => {
  return (
    <header id="top" className="uk-section header uk-padding-remove-vertical">
      <Navigation />
    </header>
  );
};

export default Header;
