import React, { useEffect, useState } from "react";

import Header from "./Header";
import Footer from "./Footer";
import "../styles/index.scss";
import PageContent from "./PageContent";
import Head from "./Head";

const Layout = ({ title, children }) => {
  // state = { ready: false }

  const [ready, setReady] = useState(false);

  useEffect(() => {
    // setReady(!ready);
    try {
      if (typeof window !== "undefined") {
        const uikit = require("uikit");
        const icons = require("uikit/dist/js/uikit-icons.min");
        uikit.use(icons);
        setReady(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, [ready]);

  // componentDidMount = () => {
  // try {
  //   if (typeof window !== "undefined") {
  //     const uikit = require("uikit")
  //     const icons = require("uikit/dist/js/uikit-icons.min")
  //     uikit.use(icons)
  //     this.setState({ ready: true })
  //   }
  // } catch (e) {
  //   console.error(e)
  // }
  // }

  // this.state.ready && (

  /* <Head title={title} /> */

  /* <Header />
      <PageContent>{this.props.children}</PageContent>
      <Footer /> */

  return (
    <>
      <Head title={title} />
      <Header />
      <PageContent>{children}</PageContent>
      <Footer />
    </>
  );
};

export default Layout;
