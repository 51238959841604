import React from "react";

const PageContent = props => {
  return (
    <main className="uk-section uk-padding-remove-vertical">
      {props.children}
    </main>
  );
};

export default PageContent;
