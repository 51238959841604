import React from "react";

const Navigation = () => {
  return (
    <div data-uk-sticky="animation: uk-animation-slide-top-small; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-light; top: 100">
      <nav className="uk-navbar-container" data-uk-navbar>
        <div className="uk-navbar-left">
          <a
            href="#top"
            className="uk-navbar-item uk-logo"
            id="logo"
            data-uk-scroll="offset: 40"
          >
            &lt;zivkarmi&gt;
          </a>
        </div>
        <div className="uk-navbar-right">
          <ul
            className="uk-navbar-nav"
            data-uk-scrollspy-nav="closest: li; scroll: true"
          >
            <li>
              <a
                href="#top"
                className="uk-visible@s"
                data-uk-scroll="offset: 40"
                data-hidden
              >
                home
              </a>
            </li>
            <li>
              <a href="#about" data-uk-scroll="offset: 40">
                about
              </a>
            </li>
            <li>
              <a href="#projects" data-uk-scroll="offset: 40">
                projects
              </a>
            </li>
            <li>
              <a href="#contact" id="nav-contact" data-uk-scroll="offset: 40">
                contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
