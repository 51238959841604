import React from "react"

const Footer = () => {
  return (
    <footer className="uk-section uk-padding-small">
      <div className="uk-container">
        <div id="copyright">© 2020 Ziv Karmi</div>
        <div id="builtwith">
          built with{" "}
          <a href="https://gatsbyjs.org/" rel="nofollow">
            Gatsby
          </a>
          ,{" "}
          <a href="https://getuikit.com/" rel="nofollow">
            UIKit
          </a>
          . hosting via <a href="https://www.netlify.com/">Netlify</a>.
        </div>
      </div>
    </footer>
  )
}

export default Footer
